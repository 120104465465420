export const COMMUNICATION_TYPE = 'message';

export enum REQUEST_EVENTS {
  GET_CONNECTION_REQUEST = 'GET_CONNECTION_REQUEST',
  CONNECT_WALLET_REQUEST = 'CONNECT_WALLET_REQUEST',
  SIGN_MESSAGE_REQUEST = 'SIGN_MESSAGE_REQUEST',
  DISCONNECT_WALLET_REQUEST = 'DISCONNECT_WALLET_REQUEST',
  GET_Y_COORDINATE_REQUEST = 'GET_Y_COORDINATE_REQUEST',
}
export enum RESPONSE_EVENTS {
  CONNECT_WALLET_RESPONSE = 'CONNECT_WALLET_RESPONSE',
  SIGN_MESSAGE_RESPONSE = 'SIGN_MESSAGE_RESPONSE',
  GET_CONNECTION_RESPONSE = 'GET_CONNECTION_RESPONSE',
  DISCONNECT_WALLET_RESPONSE = 'DISCONNECT_WALLET_RESPONSE',
  GET_Y_COORDINATE_RESPONSE = 'GET_Y_COORDINATE_RESPONSE',
}
