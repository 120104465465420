import { L2Signer } from '../../WalletSDK';
import { COMMUNICATION_TYPE, REQUEST_EVENTS, RESPONSE_EVENTS } from './events';
import {
  messageResponseListener,
} from './messageResponseListener';
import { postRequestMessage } from './postRequestMessage';
import {
  GetYCoordinateRequest,
  GetYCoordinateResponse,
  SignMessageRequest,
  SignMessageResponse,
} from './types';

export class ImxSigner implements L2Signer {
  private publicAddress;

  constructor(publicAddress: string) {
    this.publicAddress = publicAddress;
  }

  public getAddress(): string {
    return this.publicAddress;
  }

  public signMessage(rawMessage: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const listener = (event: MessageEvent) => {
        messageResponseListener<SignMessageResponse>(
          event,
          RESPONSE_EVENTS.SIGN_MESSAGE_RESPONSE,
          (messageDetails) => {
            window.removeEventListener(COMMUNICATION_TYPE, listener);

            if (!messageDetails.success) {
              reject(new Error(messageDetails.error?.message));
            }

            resolve(messageDetails.data.signedMessage);
          },
        );
      };
      window.addEventListener(COMMUNICATION_TYPE, listener);

      postRequestMessage<SignMessageRequest>({
        type: REQUEST_EVENTS.SIGN_MESSAGE_REQUEST,
        details: { starkPublicKey: this.publicAddress, message: rawMessage },
      });
    });
  }

  public getYCoordinate(): Promise<string> {
    return new Promise((resolve, reject) => {
      const listener = (event: MessageEvent) => {
        messageResponseListener<GetYCoordinateResponse>(
          event,
          RESPONSE_EVENTS.GET_Y_COORDINATE_RESPONSE,
          (messageDetails) => {
            window.removeEventListener(COMMUNICATION_TYPE, listener);

            if (!messageDetails.success) {
              reject(new Error(messageDetails.error?.message));
            }

            resolve(messageDetails.data.yCoordinate);
          },
        );
      };
      window.addEventListener(COMMUNICATION_TYPE, listener);

      postRequestMessage<GetYCoordinateRequest>({
        type: REQUEST_EVENTS.GET_Y_COORDINATE_REQUEST,
        details: { starkPublicKey: this.publicAddress },
      });
    });
  }
}
