import { ethers } from 'ethers';

import WalletConnectProvider from '@walletconnect/web3-provider';
import { addLog } from '../utils/logs';
import { registerListener, removeExistingListener } from '../events';
import { WalletConnectConnectParams, WalletSDKExternalProvider } from './types';
import { WALLET_ACTION } from '.';

export const RPC_NOT_DEFINED =
  'You cannot connect to WalletConnect Provider because RPC is not defined.';

export async function connect(
  params: WalletConnectConnectParams,
): Promise<ethers.providers.Web3Provider> {
  const { chainID, rpc } = params;
  addLog('sdk', 'walletConnect:connect');

  if (!rpc) {
    throw new Error(RPC_NOT_DEFINED);
  }

  const provider = new WalletConnectProvider({ rpc });

  await provider.enable();

  if (chainID) {
    await provider.request({
      method: WALLET_ACTION.SWITCH_CHAIN,
      params: [{ chainId: `0x${chainID}` }],
    });
  }
  registerListener(provider);

  return new ethers.providers.Web3Provider(provider);
}

export async function disconnect(
  provider: WalletSDKExternalProvider,
): Promise<void> {
  removeExistingListener(provider);
  if (!(provider as WalletConnectProvider).close) {
    addLog(
      'sdk',
      'walletConnect:disconnect - provider is not an instance of WalletConnectProvider',
    );
    return;
  }
  await (provider as WalletConnectProvider).close();
}
