import { ethers } from 'ethers';
import { WalletSDKExternalProvider } from '../L1Providers';
import { addLog } from '../utils/logs';
import { walletSdkEvents, WALLET_SDK_EVENTS } from './walletSdkEvents';

export type CustomExternalProvider = ethers.providers.ExternalProvider & {
  on: (eventName: string, callback: (args: unknown) => void) => void;
  removeListener: (eventName: string, callback: (args: unknown) => void) => void;
};

function handleChainChanged(): void {
  addLog('sdk', 'l1Provider:handleChainChanged');
  walletSdkEvents.emit(WALLET_SDK_EVENTS.WALLET_DISCONNECTING);
}

function handleAccountsChanged(): void {
  addLog('sdk', 'l1Provider:handleAccountsChanged');
  walletSdkEvents.emit(WALLET_SDK_EVENTS.WALLET_DISCONNECTING);
}

function handleClose(): void {
  addLog('sdk', 'l1Provider:handleClose');
  walletSdkEvents.emit(WALLET_SDK_EVENTS.WALLET_DISCONNECTING);
}

export function removeExistingListener(
  provider: WalletSDKExternalProvider,
) {
  provider.removeListener('chainChanged', handleChainChanged);
  provider.removeListener('accountsChanged', handleAccountsChanged);
  provider.removeListener('close', handleClose);
}

export function registerListener(
  provider: WalletSDKExternalProvider,
) {
  removeExistingListener(provider);
  provider.on('chainChanged', handleChainChanged);
  provider.on('accountsChanged', handleAccountsChanged);
  provider.on('close', handleClose);
}

export { walletSdkEvents, WALLET_SDK_EVENTS };
