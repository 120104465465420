import { addLog } from '../utils/logs';
import {
  imxWallet,
  invalidL2ProviderError,
  L2_PROVIDERS,
} from '../L2Providers';
import { SetupParams } from './types';

const L2SetupMap = {
  [L2_PROVIDERS.IMX_WALLET]: imxWallet.setupIFrame,
};

export function setup(
  params: SetupParams,
): Promise<void> {
  addLog('sdk', 'setup', { env: params.env });

  const l2ProviderParam = L2_PROVIDERS.IMX_WALLET;
  const setupL2 = L2SetupMap[l2ProviderParam];
  if (!setupL2) {
    throw new Error(invalidL2ProviderError(l2ProviderParam));
  }
  return setupL2(params.env);
}
