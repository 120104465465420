import {
  REQUEST_EVENTS,
  ConnectRequest,
  SignMessageRequest,
  GetConnectionRequest,
  DisconnectRequest,
  GetYCoordinateRequest,
} from '@imtbl/wallet-sdk-web';

const connectRequestHandler = (eventDetails: ConnectRequest) => {
  import(
    '../connectRequestHandler' /* webpackChunkName: "connectRequestHandler" */
  ).then((handler) => handler.connectRequestHandler(eventDetails));
};

const signMessageRequestHandler = (eventDetails: SignMessageRequest) => {
  import(
    './signMessage/signMessageRequestHandler' /* webpackChunkName: "signMessageRequestHandler" */
  ).then((handler) => handler.signMessageRequestHandler(eventDetails));
};

const getConnectionRequestHandler = (eventDetails: GetConnectionRequest) => {
  import(
    './getConnection/getConnectionRequestHandler' /* webpackChunkName: "getConnectionRequestHandler" */
  ).then((handler) => {
    handler.getConnectionRequestHandler(eventDetails);
  });
};

const disconnectRequestHandler = (eventDetails: DisconnectRequest) => {
  import(
    './disconnect/disconnectRequestHandler' /* webpackChunkName: "disconnectRequestHandler" */
  ).then((handler) => handler.disconnectRequestHandler(eventDetails));
};

const getYCoordinateRequestHandler = (eventDetails: GetYCoordinateRequest) => {
  import(
    './getYCoordinate/getYCoordinateRequestHandler' /* webpackChunkName: "disconnectRequestHandler" */
  ).then((handler) => handler.getYCoordinateRequestHandler(eventDetails));
};

type RequestHandlers =
  | typeof connectRequestHandler
  | typeof signMessageRequestHandler
  | typeof getConnectionRequestHandler
  | typeof disconnectRequestHandler
  | typeof getYCoordinateRequestHandler;

export const requestHandlers: Record<REQUEST_EVENTS, RequestHandlers> = {
  [REQUEST_EVENTS.CONNECT_WALLET_REQUEST]: connectRequestHandler,
  [REQUEST_EVENTS.SIGN_MESSAGE_REQUEST]: signMessageRequestHandler,
  [REQUEST_EVENTS.GET_CONNECTION_REQUEST]: getConnectionRequestHandler,
  [REQUEST_EVENTS.DISCONNECT_WALLET_REQUEST]: disconnectRequestHandler,
  [REQUEST_EVENTS.GET_Y_COORDINATE_REQUEST]: getYCoordinateRequestHandler,
};
