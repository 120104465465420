import { ethers } from 'ethers';

import { addLog } from '../utils/logs';
import { deleteProviderPreference, getProviderPreference } from '../utils/providerPreference';
import {
  L1_PROVIDERS,
  metamask,
  walletConnect,
  WalletSDKExternalProvider,
} from '../L1Providers';
import { imxWallet, L2_PROVIDERS } from '../L2Providers';
import { WalletConnection, L2Signer } from '../WalletSDK';

const L1DisconnectMap = {
  [L1_PROVIDERS.METAMASK]: metamask.disconnect,
  [L1_PROVIDERS.WALLET_CONNECT]: walletConnect.disconnect,
};

const L2DisconnectMap = {
  [L2_PROVIDERS.IMX_WALLET]: imxWallet.disconnect,
};

async function handleL1Disconnection(l1Signer: ethers.Signer) {
  const providerPreferences = getProviderPreference();
  if (!providerPreferences) return;

  const { l1ProviderPreference } = providerPreferences;

  deleteProviderPreference();

  const disconnectL1 = L1DisconnectMap[l1ProviderPreference];
  if (!disconnectL1) return;

  const { provider } = l1Signer.provider as ethers.providers.Web3Provider;

  await disconnectL1(provider as WalletSDKExternalProvider);
}

async function handleL2Disconnection(l2Signer: L2Signer) {
  const l2ProviderParam = L2_PROVIDERS.IMX_WALLET;
  const disconnectL2 = L2DisconnectMap[l2ProviderParam];

  const starkPublicKey = await l2Signer.getAddress();

  await disconnectL2(starkPublicKey);
}

export async function disconnect(
  walletConnection: WalletConnection,
): Promise<void> {
  addLog('sdk', 'disconnect', { walletConnection });

  const { l1Signer, l2Signer } = walletConnection;

  await handleL1Disconnection(l1Signer);

  await handleL2Disconnection(l2Signer);
}
