import {
  ImmutableProvider, WalletConnection, ConnectParams, isImmutableProvider,
  isWalletConnection, InvalidWalletConnectionError,
} from '@imtbl/provider-sdk-web';
import {
  IMX_WALLET_MANAGER_PROVIDER_PREFERENCE as PROVIDER_PREFERENCE,
} from './constants';
import { addLog } from './utils/logs';
import { walletSdkEvents, WALLET_SDK_EVENTS } from './events';

export class WalletManager {
  private walletConnection: WalletConnection | null;

  private baseWallets: Array<ImmutableProvider> = [];

  public constructor() {
    this.handleImxEvents();
  }

  private handleImxEvents() {
    walletSdkEvents.on(WALLET_SDK_EVENTS.WALLET_DISCONNECTING, () => {
      this.disconnect();
    });
  }

  private hasLocalStorage() {
    return typeof window !== 'undefined' && window.localStorage;
  }

  public isConnected() {
    return !!this.walletConnection;
  }

  public async disconnect() {
    if (this.hasLocalStorage()) {
      const store = window.localStorage;
      const providerPreference = store.getItem(PROVIDER_PREFERENCE);

      store.removeItem(
        PROVIDER_PREFERENCE,
      );

      addLog('sdk', 'providerPreferenceCleared', { providerPreference });
    }

    if (this.walletConnection) {
      this.walletConnection = null;
      walletSdkEvents.emit(WALLET_SDK_EVENTS.WALLET_DISCONNECTED);
    }
  }

  public getWalletsFromWindow() : Array<ImmutableProvider> {
    const validWallets: Array<ImmutableProvider> = [];
    const windowProviders = window.imxProviders || [];

    return [windowProviders]
      .flat()
      .reduce((acc, v) => {
        if (isImmutableProvider(v)) {
          acc.push(v);
        }

        return acc;
      }, validWallets);
  }

  private persistConnection(connectParams: ConnectParams, walletConnection: WalletConnection) {
    if (!this.hasLocalStorage()) return;

    const providerPreference = {
      providerName: walletConnection.providerName,
      ...connectParams,
    };

    addLog('sdk', 'saveProviderPreference', { providerPreference });

    window.localStorage.setItem(
      PROVIDER_PREFERENCE,
      JSON.stringify(providerPreference),
    );
  }

  private async reconnectWallet() {
    if (!this.hasLocalStorage()) {
      return null;
    }

    const providerPreference = window.localStorage.getItem(
      PROVIDER_PREFERENCE,
    );

    if (!providerPreference) {
      return null;
    }

    const { providerName, chainID, rpc } = JSON.parse(providerPreference);

    const provider = this.getAvailableWallets().find(
      (prov) => prov.providerName === providerName,
    );

    return provider && this.enrichedConnect({
      chainID,
      rpc,
    } as ConnectParams, provider.connect);
  }

  private async enrichedConnect(
    connectParams: ConnectParams,
    providerConnectFunc: (params: ConnectParams)=>(
      Promise<WalletConnection>
    ),
  ): Promise<WalletConnection> {
    const connection = await providerConnectFunc(connectParams);

    if (!isWalletConnection(connection)) {
      throw new InvalidWalletConnectionError();
    }

    this.walletConnection = connection;
    this.persistConnection(connectParams, this.walletConnection);

    walletSdkEvents.emit(
      WALLET_SDK_EVENTS.CONNECTION_UPDATED,
      this.walletConnection,
    );

    return this.walletConnection;
  }

  public getAvailableWallets(): Array<ImmutableProvider> {
    const enrConn = this.enrichedConnect.bind(this);

    return [...this.baseWallets, ...this.getWalletsFromWindow()].map(
      (w) => ({
        ...w,
        async connect(params: ConnectParams) { return enrConn(params, w.connect); },
      }),
    );
  }

  public async getWalletConnection() {
    if (this.walletConnection) {
      return this.walletConnection;
    }

    return this.reconnectWallet();
  }
}
