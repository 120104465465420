import { Signer as L1Signer } from 'ethers';

import {
  IRPCMap, setup, SetupParams,
} from './setup';
import { connect as internalConnect, ConnectParams } from './connect';
import { disconnect as internalDisconnect } from './disconnect';
import { getConnection } from './getConnection';
import { walletSdkEvents, WALLET_SDK_EVENTS } from './events';

// Representation of the L2Signer interface contained in the CoreSDK
export type L2Signer = {
  getAddress(): string | Promise<string>;
  signMessage(message: string): Promise<string>;
};

// Representation of the WalletConnection interface contained in the CoreSDK
export type WalletConnection = {
  l1Signer: L1Signer;
  l2Signer: L2Signer;
};

export class WalletSDK {
  private walletConnection: WalletConnection | null;

  private rpc: IRPCMap | undefined;

  private chainID: number | undefined;

  private constructor({
    rpc,
    chainID,
  }: {
    rpc?: IRPCMap;
    chainID?: number;
  }) {
    this.rpc = rpc;
    this.chainID = chainID;
    this.handleImxEvents();
  }

  private handleImxEvents() {
    walletSdkEvents.on(WALLET_SDK_EVENTS.WALLET_DISCONNECTING, () => {
      this.disconnect();
    });
  }

  public async connect(params: ConnectParams): Promise<WalletConnection> {
    const connectionResult = await internalConnect({
      ...params,
      rpc: this.rpc,
      chainID: this.chainID,
    });

    this.walletConnection = connectionResult;
    walletSdkEvents.emit(
      WALLET_SDK_EVENTS.CONNECTION_UPDATED,
      this.walletConnection,
    );
    return connectionResult;
  }

  public isConnected() {
    return !!this.walletConnection;
  }

  public async getWalletConnection(): Promise<WalletConnection | null> {
    if (this.isConnected()) {
      return this.walletConnection;
    }

    const connection = await getConnection({
      rpc: this.rpc,
      chainID: this.chainID,
    });
    if (connection) {
      this.walletConnection = connection;
      walletSdkEvents.emit(
        WALLET_SDK_EVENTS.CONNECTION_UPDATED,
        this.walletConnection,
      );
      return connection;
    }
    return null;
  }

  public async disconnect() {
    if (this.walletConnection) {
      internalDisconnect(this.walletConnection);
      walletSdkEvents.emit(WALLET_SDK_EVENTS.WALLET_DISCONNECTED);
    }
  }

  public static async build(params: SetupParams): Promise<WalletSDK> {
    await setup(params);
    const { rpc, chainID } = params;
    const sdk = new WalletSDK({ rpc, chainID });
    // keep it async, it would fire the events once the walletconnection is updated
    sdk.getWalletConnection();
    return sdk;
  }
}
