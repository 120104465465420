import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';

import { addLog } from '../utils/logs';
import {
  CustomExternalProvider,
  registerListener,
  removeExistingListener,
} from '../events';
import { MetamaskConnectParams, WalletSDKExternalProvider } from './types';
import { WALLET_ACTION } from '.';

export const L1_METAMASK_ERRORS = {
  PROVIDER_NOT_FOUND: 'The MetaMask provider was not found.',
};

export async function connect({
  chainID,
}: MetamaskConnectParams): Promise<ethers.providers.Web3Provider> {
  addLog('sdk', 'metamask:connect');

  const provider =
    (await detectEthereumProvider()) as ethers.providers.ExternalProvider;

  if (!provider?.request) {
    throw new Error(L1_METAMASK_ERRORS.PROVIDER_NOT_FOUND);
  }

  await provider.request({ method: WALLET_ACTION.CONNECT });
  if (chainID) {
    await provider.request({
      method: WALLET_ACTION.SWITCH_CHAIN,
      params: [{ chainId: `0x${chainID}` }],
    });
  }
  registerListener(provider as CustomExternalProvider);

  return new ethers.providers.Web3Provider(provider);
}

export async function disconnect(provider: WalletSDKExternalProvider) {
  removeExistingListener(provider);
}
