import {
  InternalConnectParams,
  L1_PROVIDERS,
  metamask,
  walletConnect,
} from '../L1Providers';
import { imxWallet, L2_PROVIDERS } from '../L2Providers';
import { deleteProviderPreference, getProviderPreference } from '../utils/providerPreference';
import { WalletConnection } from '../WalletSDK';

const L1RehydrateMap = {
  [L1_PROVIDERS.METAMASK]: metamask.connect,
  [L1_PROVIDERS.WALLET_CONNECT]: walletConnect.connect,
};

const L2RehydrateMap = {
  [L2_PROVIDERS.IMX_WALLET]: imxWallet.getConnection,
};

function deleteProviderPreferenceAndReturn() {
  deleteProviderPreference();
  return null;
}

export async function getConnection(
  params: InternalConnectParams,
): Promise<WalletConnection | null> {
  const providerPreference = getProviderPreference();

  if (!providerPreference) return null;
  const { l1ProviderPreference, l2ProviderPreference } = providerPreference;
  const getConnectionOnL1 = L1RehydrateMap[l1ProviderPreference];
  if (!getConnectionOnL1) return deleteProviderPreferenceAndReturn();
  const l1Provider = await getConnectionOnL1(params);
  const etherAddress = await l1Provider.getSigner().getAddress();

  const getConnectionOnL2 = L2RehydrateMap[l2ProviderPreference];
  if (!getConnectionOnL2) return deleteProviderPreferenceAndReturn();

  const l2Signer = await getConnectionOnL2(etherAddress);

  if (!l2Signer) return deleteProviderPreferenceAndReturn();
  return { l1Signer: l1Provider.getSigner(), l2Signer };
}
