import { addLog, REQUEST_EVENTS } from '@imtbl/wallet-sdk-web';
import { requestHandlers } from './requestHandlers';

export function sdkEventListener(event: MessageEvent) {
  const { referrer } = document;
  if (!referrer) {
    return;
  }
  if (event.origin !== new URL(document.referrer).origin) {
    return;
  }

  const message = event.data;
  const messageType = message.type as REQUEST_EVENTS;
  const eventHandler = requestHandlers[messageType];
  const eventDetails = message.details;

  if (!eventHandler) {
    addLog('imx-wallet', 'sdkEventListener - unknown event', event);
    return;
  }

  addLog('imx-wallet', 'sdkEventListener', { messageType, eventDetails });

  eventHandler(eventDetails);
}

export function createEventListener() {
  window.addEventListener('message', sdkEventListener);
}
