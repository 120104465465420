import * as metamask from './metaMask';
import * as walletConnect from './walletConnect';

export const WALLET_ACTION = {
  SWITCH_CHAIN: 'wallet_switchEthereumChain',
  CONNECT: 'eth_requestAccounts',
};

export { metamask };
export { walletConnect };
export * from './errors';
export * from './types';
export * from './constants';
