import { L1_PROVIDERS } from '../L1Providers';
import { L2_PROVIDERS } from '../L2Providers';
import { addLog } from './logs';

interface ProviderPreference {
  l1ProviderPreference: L1_PROVIDERS;
  l2ProviderPreference: L2_PROVIDERS;
}

export const PROVIDER_PREFERENCE = 'PROVIDER_PREFERENCE';
const hasLocalStorage = () =>
  typeof window !== 'undefined' && window.localStorage;

export function saveProviderPreference(
  l1ProviderPreference: L1_PROVIDERS,
  l2ProviderPreference: L2_PROVIDERS,
) {
  if (hasLocalStorage()) {
    const providerPreference = `${l1ProviderPreference}:${l2ProviderPreference}`;
    addLog('sdk', 'saveProviderPreference', { providerPreference });

    window.localStorage.setItem(
      PROVIDER_PREFERENCE,
      providerPreference,
    );
  }
}

export function getProviderPreference(): ProviderPreference | null {
  if (!hasLocalStorage()) {
    return null;
  }

  const providerPreference = window.localStorage.getItem(PROVIDER_PREFERENCE);
  if (!providerPreference) {
    return null;
  }
  const [l1ProviderPreference, l2ProviderPreference] = providerPreference.split(':');
  addLog('sdk', 'getProviderPreference', { l1ProviderPreference, l2ProviderPreference });
  return { l1ProviderPreference, l2ProviderPreference } as ProviderPreference;
}

export function deleteProviderPreference() {
  if (hasLocalStorage()) {
    addLog('sdk', 'deleteProviderPreference');
    window.localStorage.removeItem(PROVIDER_PREFERENCE);
  }
}
