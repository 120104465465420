import { addLog } from '../utils/logs';
import { ConnectParams } from './types';
import {
  metamask,
  invalidL1ProviderError,
  walletConnect,
  L1Connector,
  L1_PROVIDERS,
  InternalConnectParams,
} from '../L1Providers';
import { imxWallet, invalidL2ProviderError, L2_PROVIDERS } from '../L2Providers';
import { WalletConnection } from '../WalletSDK';
import { saveProviderPreference } from '../utils/providerPreference';

const L1ConnectMap = {
  [L1_PROVIDERS.METAMASK]: metamask.connect,
  [L1_PROVIDERS.WALLET_CONNECT]: walletConnect.connect,
};
const L2ConnectMap = {
  [L2_PROVIDERS.IMX_WALLET]: imxWallet.connect,
};

export async function connect(
  params: InternalConnectParams & ConnectParams,
): Promise<WalletConnection> {
  addLog('sdk', 'connect', { params });

  const { provider, ...options } = params;

  const connectL1: L1Connector | undefined = L1ConnectMap[provider];
  if (!connectL1) {
    throw new Error(invalidL1ProviderError(provider));
  }

  const l1Provider = await connectL1(options);

  const l2ProviderParam = L2_PROVIDERS.IMX_WALLET;
  const connectL2 = L2ConnectMap[l2ProviderParam];
  if (!connectL2) {
    throw new Error(invalidL2ProviderError(l2ProviderParam));
  }

  const l2Signer = await connectL2(l1Provider);

  saveProviderPreference(provider, L2_PROVIDERS.IMX_WALLET);

  return { l1Signer: l1Provider.getSigner(), l2Signer };
}
