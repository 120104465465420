export function addLog(
  from: 'sdk' | 'imx-wallet',
  method: string,
  content?: any,
) {
  if (process.env.ENABLE_DEV_LOG === 'true') {
    // eslint-disable-next-line no-console
    console.log(`[${from}]`, method, content);
  }
}
